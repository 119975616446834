const maxTimerJump = 3;
const slideChangeTimeSec = 15;
const speed = 0.024; //(d/ms)   360/30*1000 = 36/3000 = 12/1000      360 d / 30 sec 
const optiveRadious = 50;
const centerPt = 300;

function hexToRgbA(hex, a) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
    }
    throw new Error('Bad Hex');
}

function Bezier(time, startValue, endValue, duration) {
    const completion = time / duration;
    return startValue + (endValue - startValue) * (completion * completion * (3.0 - 2.0 * completion));
}

const drawCenter = (ctx, index) => {
    ctx.beginPath();
    ctx.arc(centerPt, centerPt, optiveRadious * 3, 0, 2 * Math.PI);

    const radgrad = ctx.createRadialGradient(centerPt, centerPt, optiveRadious * 2, centerPt, centerPt, optiveRadious * 3);
    radgrad.addColorStop(0, optives[index].color1);

    radgrad.addColorStop(0, optives[index].color1);
    radgrad.addColorStop(0.3, optives[index].color1);

    radgrad.addColorStop(0.9, optives[index].color2);
    radgrad.addColorStop(1, optives[index].color3);

    ctx.fillStyle = radgrad;
    ctx.fill();
}

const drawOptives = (ctx, angle) => {
    const stepAngle = 360 / optives.length;
    for (let i = 0; i < optives.length; i++) {
        const pt = new Point(angle - stepAngle * i, optiveRadious * 4.5);
        optives[i].draw(ctx, centerPt + pt.x, centerPt + pt.y);
    }
}

const findNearestOptive = (x, y) => {
    // console.log(`Checking: ${x.toFixed(2)} ${y.toFixed(2)}  [active:${activeIndex}, currentAngle:${currentValue}]`);

    for (let i = 0; i < optives.length; i++) {
        const pt = new Point(optives[i].angle - currentValue, optiveRadious * 4.5);

        const ptx = centerPt - pt.x;
        const pty = centerPt + pt.y;

        const dist = Math.sqrt((ptx - x) * (ptx - x) + (pty - y) * (pty - y));
        // console.log(`${i}: ${ptx.toFixed(2)}, ${pty.toFixed(2)}, ${dist.toFixed(2)} ${optives[i].angle.toFixed(2)}  ${optives[i].name}`);

        if (dist < optiveRadious * 1.2) {
            return { index: i, optive: optives[i] };
        }
    }
    return null;
}

class Point {
    constructor(angle, radious) {
        const rad = angle * Math.PI / 180;

        this.x = radious * Math.sin(rad);
        this.y = - radious * Math.cos(rad);
    }
}

class Optive {
    constructor(id, name, icon, color1, color2) {
        this.divId = id;
        this.name = name;
        this.iconFile = icon;
        this.color1 = color1;
        this.color2 = color2;
        this.color3 = hexToRgbA(color2, 0);

        this.active = false;
        this.pos = 0; // Angular position
    }

    draw(ctx, x, y) {
        //    console.log(`Drawing: ${this.name}  (${this.color2}-${this.color3} - ${this.active}`);

        if (this.active) {
            ctx.beginPath();
            ctx.arc(x, y, optiveRadious + 15, 0, 2 * Math.PI);

            const radgrad = ctx.createRadialGradient((x), (y), optiveRadious - 2, x, y, optiveRadious + 15);
            radgrad.addColorStop(0, this.color3);
            radgrad.addColorStop(0, 'rgba(100,100,100,1)');
            radgrad.addColorStop(1, 'rgba(255,255,255,0)');

            ctx.fillStyle = radgrad;
            ctx.fill();
        }
        ctx.beginPath();
        ctx.arc(x, y, optiveRadious, 0, 2 * Math.PI);

        // Create gradients
        const radgrad = ctx.createRadialGradient((x), (y), optiveRadious * 0.7, x, y, optiveRadious);
        radgrad.addColorStop(0, this.color1);
        radgrad.addColorStop(0.7, this.color1);

        if (!this.active) {
            radgrad.addColorStop(0.9, this.color2);
            radgrad.addColorStop(1, this.color3);
        }

        ctx.fillStyle = radgrad;
        ctx.fill();

        ctx.fillStyle = 'white';
        ctx.font = "bold 16px sans-serif";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(this.name, x, y);
    }
}

// https://www.w3schools.com/colors/colors_picker.asp
// 60% => 85%

const optives = [
    new Optive("x405", "405(d)", new URL('../images/logo/405d.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("fda", "FDA", new URL('../images/logo/fda.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("ocr", "OCR", new URL('../images/logo/ocr.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("onc", "ONC", new URL('../images/logo/onc.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("aspr", "ASPR", new URL('../images/logo/aspr.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("hc3", "HC3", new URL('../images/logo/hc3.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("arpa", "ARPA-H", new URL('../images/logo/arpa.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("ons","ONS", new URL('../images/logo/ons.png', import.meta.url), '#0033cc', '#809fff'),
    new Optive("cms", "CMS", new URL('../images/logo/cms.png', import.meta.url), '#0033cc', '#809fff'),
];

const canvas = document.getElementById("canvas");
const ctx = canvas.getContext("2d");
const imgX = document.getElementById("icon-x");

const rect = canvas.getBoundingClientRect();
let offsetX = rect.x; //  canvas.offsetLeft;
let offsetY = rect.y; //canvas.offsetTop;
// console.log(offsetX, offsetY);


const iconSize = optiveRadious * 2;
optives[0].active = true;
imgX.src = optives[0].iconFile;

const stepAngle = 360 / optives.length;
for (let i = 0; i < optives.length; i++) {
    optives[i].angle = stepAngle * i;
}

let raf;
let timer;
let fadeTimer;
let activeIndex = 0;
let currentValue = 0;
let startValue = 0;
let finalValue = 0;
let running = false;
let startTime;
let duration = 1;

const incrementOptive = () => {

    let idx;
    if (activeIndex === 0) {
        idx = Math.max(1,maxTimerJump+1 - Math.floor(Math.sqrt( Math.floor(Math.random() * (maxTimerJump+2) * (maxTimerJump+2)))));
    }
    else {
        idx = Math.floor(Math.random() * maxTimerJump) + 1;
    }
    // console.log(`From ${activeIndex} => ${idx}` );

    idx += activeIndex;
    if (idx >= optives.length) idx = 0;

    setCurrentOptive(idx);
}

const setCurrentOptive = (idx) => {

    try {
        if (idx >= optives.length) {
            console.log(`Skipping: ${idx}`);
            return;
        }
        // console.log(`Setting Active Optive to ${idx}, (old:${activeIndex})`);

        for (let i = 0; i < optives.length; i++) {
            optives[i].active = (i === idx);
        }
        startValue = optives[activeIndex].angle;
        currentValue = startValue;
        finalValue = optives[idx].angle;
        if (finalValue < startValue) {
            finalValue += 360;
        }

        // Remove last active detail...
        if (optives[activeIndex].divId) {
            const xdiv = document.querySelector(`.teams .wheel-area .detail >#${optives[activeIndex].divId}`);
            if (xdiv) {
                xdiv.classList.remove("show-w");
                xdiv.classList.add("hidden-w");
            }
            else {
                console.log("Did not find", activeIndex, optives[activeIndex].divId);
            }
        }

        running = true;
        activeIndex = idx;
        optives[idx].active = true;
        startTime = undefined;
        duration = Math.round((finalValue - startValue) / speed);

//         const newSrc =  new URL(optives[activeIndex].iconFile, import.meta.url);
// console.log(newSrc);
        imgX.src = optives[activeIndex].iconFile;

        {
            let divId = optives[activeIndex].divId;
            if (!divId) divId = "xxx";
            const xdiv = document.querySelector(`.teams .wheel-area .detail >#${divId}`);
            if (xdiv) {
                clearTimeout(fadeTimer);
                fadeTimer = window.setTimeout(() => {
                    xdiv.classList.remove("hidden-w");
                    xdiv.classList.add("show-w");
                }, 1000);
            }
        }

        raf = window.requestAnimationFrame(animate);
        clearTimeout(timer);

    } catch (error) {
        console.log(`Active: ${activeIndex} : to ${idx}`);
        console.error(error);
    }
}

const animate = (time) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   drawCenter(ctx, activeIndex);

    if (startTime === undefined) {
        // console.log("Starting animation");
        startTime = time;
    }

    const delta = time - startTime;
    if (delta >= duration) {
        currentValue = finalValue;
    }
    else {
        currentValue = Bezier(delta, startValue, finalValue, duration);
    }

    //      console.log(delta, time, currentValue);
    drawOptives(ctx, currentValue);

    if (currentValue >= finalValue) {
        running = false;
        // console.log(`Reached end at ${delta} (~${duration - delta})`);

        timer = setTimeout(() => {
            incrementOptive();
        }, slideChangeTimeSec * 1000);
    }
    else {
        raf = requestAnimationFrame(animate);

    }
};

const drawBall = (ctx, x, y) => {
    ctx.beginPath(); ctx.arc(x, y, 10, 0, Math.PI * 2, true); ctx.closePath(); ctx.fillStyle = 'blue'; ctx.fill();
};

canvas.addEventListener("click", (e) => {
    if (!running) {

        let x = Math.round(e.clientX - offsetX);
        let y = Math.round(e.clientY - offsetY);

        // console.log(`Clicked: x:${e.clientX}, y:${e.clientY} => ${x} : ${y}[${offsetX} / ${offsetY}]`);

        if (x < 0 || x > centerPt * 2 || y < 0 || y > centerPt * 2) {
            // console.log("Fixing offset....");
            const rect = canvas.getBoundingClientRect();
            offsetX = Math.round(rect.x); //  canvas.offsetLeft;
            offsetY = Math.round(rect.y); //canvas.offsetTop;

            x = Math.round(e.clientX - offsetX);
            y = Math.round(e.clientY - offsetY);

            console.log(`Updated: x:${e.clientX}, y:${e.clientY} => ${x} : ${y} [${offsetX} / ${offsetY}]`);
        }

        // drawBall(ctx, x, y);

        const nearer = findNearestOptive(x, y);
        if (nearer) {
            // console.log(`I am near ${nearer.optive.name}`);
            setCurrentOptive(nearer.index);
        }
    }
});

requestAnimationFrame(animate);
